<template>
  <el-card :body-style="{ padding: '0px' }" class="card">
    <el-input
      v-model="textarea"
      type="textarea"
      :rows="3"
      placeholder="有什么新鲜事想分享给大家?"
    />
    <el-upload
      :action="actionUrl"
    >
      <el-button><i class="el-icon-picture-outline">图片</i></el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-row style="text-align: right">
      <el-button round type="submit" @click="onSubmit">发送</el-button>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'TextCard',
  data() {
    return {
      actionUrl: process.env.VUE_APP_SERVER_URL + '/api/content/timeline',
      textarea: '',
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    onSubmit() {
      console.log('发送状态')
    }
  }
}
</script>

<style scoped>
.el-button--submit.is-active,
.el-button--submit:active {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
.el-button--submit:focus,
.el-button--submit:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
.el-button--submit {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
</style>
