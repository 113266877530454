<template>
  <el-row v-if="userInfo !== null" style="height: 80vh;">
    <el-scrollbar style="width: 100%; height: 100%;">
      <el-row class="movie-list">
        <el-col :md="8">
          <user-avatar-card :user-avatar="userInfo" />
        </el-col>
        <el-col :md="8">
          <el-row>
            <text-card />
          </el-row>
          <el-row>
            <el-tabs v-model="activeName" @tab-click="tabClick">
              <el-tab-pane label="视频" name="video">
                <div v-if="activeName === 'video'">
                  <el-row
                    v-if="dataList.length !== 0"
                    v-infinite-scroll="load"
                    infinite-scroll-disabled="loading"
                    infinite-scroll-distance="10"
                  >
                    <el-col>
                      <el-row v-for="(video, index) in dataList" :key="index">
                        <side-video-card :video="video" />
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="状态" name="status">
                <div v-if="activeName === 'status'">
                  <el-col :md="15">
                    <el-row v-for="(status, index) in dataList" :key="index" :md="15" :sm="12" :xs="12">
                      <status-card :status="status" />
                    </el-row>
                  </el-col>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </el-col>
      </el-row>
    </el-scrollbar>
  </el-row>
</template>

<script>
import TextCard from '@/components/card/TextCard'
import StatusCard from '@/components/card/StatusCard'
import SideVideoCard from '@/components/card/SideVideoCard'
import UserAvatarCard from '@/components/card/UserAvatarCard'

import { videoTimeline } from '@/api/video'
import { getAuthedUser } from '@/utils/auth'

export default {
  name: 'Timeline',
  components: { TextCard, StatusCard, SideVideoCard, UserAvatarCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      nextId: 0,
      userInfo: null,
      videoInfo: null,
      dataList: [],
      activeName: 'video',
      loading: false
    }
  },
  created() {
    const loginUser = getAuthedUser()
    if (loginUser === null) {
      return null
    }

    this.userInfo = loginUser
    document.title = loginUser.screenName + '的时间线'
    this.videoTimelineWrapper(this.nextId)
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    load() {
      this.loading = true
      setTimeout(() => {
        this.videoTimelineWrapper(this.nextId)
      }, 1000)
    },
    tabClick(tab) {
      this.nextId = 0
      const tabName = tab.name
      if (tabName === 'video') {
        this.videoTimelineWrapper(this.nextId)
      } else if (tabName === 'status') {
        this.statusTimelineWrapper(this.nextId)
      }
    },
    statusTimelineWrapper(nextId) {
      this.$message.info('暂未实现')
    },
    videoTimelineWrapper(nextId) {
      videoTimeline(nextId).then(resp => {
        if (resp.code === 0) {
          for (const item of resp.data.list) {
            this.dataList.push(item)
          }
          this.nextId = resp.data.nextId
          this.loading = false
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 3%;
  padding-right: 3%;
}
</style>
