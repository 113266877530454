<template>
  <el-card :body-style="{ padding: '0px' }" class="card">
    <el-row>
      <el-col :md="3">
        <router-link target="_blank" :to="`/user/` + status.user.userId">
          <el-avatar>
            <el-image :src="status.user.avatarUrl" />
          </el-avatar>
        </router-link>
      </el-col>
      <el-col :md="12">
        <el-row>
          <router-link target="_blank" :to="`/user/` + status.user.userId">
            <span>{{ status.user.screenName }}</span>
          </router-link>
        </el-row>
        <el-row>
          <router-link target="_blank" :to="`/status/` + status.statusId">
            <span>{{ status.createdAt }} 来自 微博网页版</span>
          </router-link>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <span v-html="status.text" />
    </el-row>
    <el-row v-if="status.imageUrls.length !== 0">
      <el-col :md="6" v-for="imageUrl in status.imageUrls" :key="imageUrl.thumbnailUrl">
        <el-image
          lazy
          fit="cover"
          class="coverImg"
          :src="imageUrl.thumbnailUrl"
          @click="showImage(status.imageUrls)">
        </el-image>
      </el-col>
    </el-row>
    <el-row v-if="status.audioUrl !== undefined && status.audioUrl !== null">
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'StatusCard',
  components: {},
  props: {
    status: {
      type: Object,
      default: null
    },
    // 时间前的描述
    dateTit: {
      type: String,
      default: ''
    }
  },
  methods: {
    showImage(imageUrls) {
      const imgs = []
      for (const i of imageUrls) {
        imgs.push(i.originalUrl)
      }
      this.$viewerApi({
        images: imgs,
        options: {
          movable: true,
          fullscreen: false,
          keyboard: true
        }
      })
    },
  }
}
</script>

<style scoped>
.time {
  font-size: 15px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.tit {
  font-weight: 700;
  font-size: 18px;

  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*行数*/
  -webkit-box-orient: vertical;
}

.num {
  position: relative;
  font-size: 15px;
  padding-top: 9px;
}

/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .tit {
    font-weight: 600;
    font-size: 12px;
    height: 32px;
  }
  .time {
    font-size: 10px;
    color: #999;
  }
  .num {
    font-size: 9px;
    padding-top: 3px;
  }
  .bottom {
    margin-top: 2px;
    line-height: 7px;
  }
  .coverImg {
    height: 120px !important;
  }
}

.coverImg {
  width: 100%;
  height: 175px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

/*.card:hover {
  !*鼠标放上之后元素变成1.06倍大小*!
  transform: scale(1.06);
}*/
.imgs {
  position: relative;
}
.play-icon {
  position: absolute;
  /*top: -15px;*/
  right: 2%;
  bottom: 5px;
  z-index: 7;
  width: 40px;
}
</style>
